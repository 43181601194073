import classnames from 'classnames'
import React from 'react'
import stylesheet from './Card.scss'
import { CardProps } from './props'

const BaseCard = React.forwardRef<HTMLElement, CardProps>(
  function CardForwardRef(
    { className, children, as: Comp = 'div', ...props },
    ref
  ) {
    return (
      <Comp
        ref={ref}
        className={classnames(
          stylesheet.container,
          {
            [stylesheet.clickable]: props.onClick,
          },
          className
        )}
        {...props}
      >
        {children}
      </Comp>
    )
  }
)

const CardHeader = React.forwardRef<HTMLElement, CardProps>(
  ({ className, children, as: Comp = 'div', ...props }, ref) => {
    return (
      <Comp
        ref={ref}
        className={classnames(stylesheet.header, className)}
        {...props}
      >
        {children}
      </Comp>
    )
  }
)

const CardContent = React.forwardRef<HTMLElement, CardProps>(
  ({ className, children, as: Comp = 'div', ...props }, ref) => {
    return (
      <Comp
        ref={ref}
        className={classnames(stylesheet.content, className)}
        {...props}
      >
        {children}
      </Comp>
    )
  }
)

const CardFooter = React.forwardRef<HTMLElement, CardProps>(
  ({ className, children, as: Comp = 'div', ...props }, ref) => {
    return (
      <Comp
        ref={ref}
        className={classnames(stylesheet.footer, className)}
        {...props}
      >
        {children}
      </Comp>
    )
  }
)
interface Card extends React.ForwardRefExoticComponent<CardProps> {
  (props: CardProps): JSX.Element
  Header: typeof CardHeader
  Content: typeof CardContent
  Footer: typeof CardFooter
}

Object.assign(BaseCard, {
  Header: CardHeader,
  Content: CardContent,
  Footer: CardFooter,
})

const Card = BaseCard as Card

export { Card }
