import { matchPath } from 'react-router-dom'
import { eventTracker, EVENTS, PROPERTIES } from '@voltus/event-tracking'
import { demoConfig } from '@voltus/network'
import { RouteConfig } from '@voltus/types'

/**
 * Enable demo mode on the site and force reload.
 */
export const enableDemo = () => {
  window.localStorage.setItem('__DEMO__', 'true')
  window.location.reload()
  eventTracker.setSuperProperties({ [PROPERTIES.DEMO_MODE]: true })
  eventTracker.track(EVENTS.DEMO_MODE_ENABLED)
}

/**
 * Disable demo mode on the site and force reload.
 */
export const disableDemo = () => {
  window.localStorage.setItem('__DEMO__', 'false')
  window.location.reload()
  eventTracker.clearSuperProperties(PROPERTIES.DEMO_MODE)
  eventTracker.track(EVENTS.DEMO_MODE_DISABLED)
}

/**
 * Check if demo mode is enabled.
 */
export const isDemoEnabled = (): boolean => {
  try {
    return (
      JSON.parse(window.localStorage.getItem('__DEMO__') as string) ?? false
    )
  } catch (error) {
    console.error(error)
    return false
  }
}

/**
 * If demo mode is enabled, filter a list of routes to only those routes that
 * are part of the demo's navigationList.
 * If demo mode is not enabled, return all routes.
 */
export const filterRoutesForDemo = (routes: Array<RouteConfig>) => {
  return routes.filter((route) => {
    if (!isDemoEnabled()) {
      return true
    }

    return Object.keys(demoConfig.allowedRoutes).some((allowedRoute) =>
      matchPath(allowedRoute, route.path)
    )
  })
}
