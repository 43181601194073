export enum BUTTON_KIND {
  DEFAULT = 'DEFAULT',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  TRANSPARENT = 'TRANSPARENT',
  INLINE = 'INLINE',
  DEFAULT_OUTLINE = 'DEFAULT_OUTLINE',
  SUCCESS_OUTLINE = 'SUCCESS_OUTLINE',
  WARNING_OUTLINE = 'WARNING_OUTLINE',
  DANGER_OUTLINE = 'DANGER_OUTLINE',
}

export enum BUTTON_SIZE {
  SMALL = 'small',
  LARGE = 'large',
}
