import classnames from 'classnames'
import noop from 'lodash/noop'
import React from 'react'
import { AnyObject } from '@voltus/types'

import { Box } from '../../Box'
import { useNavbarContext } from '../navbar-context'
import stylesheet from './NavItem.scss'

export type location = {
  pathname?: string
  search?: string
  state?: { from: string }
}

export type NavItemProps = {
  is?: React.ElementType
  className?: string
  itemClassName?: string
  hidden?: boolean
  onClick?: (evt: MouseEvent) => void
  active?: boolean
  to: string | location
  eventName?: string
  eventProps?: AnyObject
  children: React.ReactNode
}

/**
 * Link item to show nav item links. This allows for customizing the behavior of a given
 * Link within the navbar
 *
 */
function NavItem({
  className,
  itemClassName,
  hidden,
  is: Component = 'a',
  children,
  onClick = noop,
  active,
  to,
  ...props
}: NavItemProps): JSX.Element | null {
  const { handleChange } = useNavbarContext()

  return hidden ? null : (
    <li
      className={classnames(stylesheet.navItem, className, {
        [stylesheet.active]: active,
      })}
    >
      <Component
        {...props}
        onClick={(e: MouseEvent) => {
          handleChange(to)
          onClick(e)
        }}
        className={classnames(
          stylesheet.navItemLink,
          stylesheet.navItemActiveContainer,
          itemClassName
        )}
        to={to}
        href={to}
      >
        <Box height={3} width="100%" bg="transparent" />
        {children}
        <Box
          height={3}
          width="100%"
          bg={active ? 'greens.50' : 'transparent'}
        />
      </Component>
    </li>
  )
}

export default NavItem
