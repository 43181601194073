import { HOST_TARGET } from '@voltus/constants'

const PROD = 'production'
const DEV = 'development'
const TEST = 'test'
const PLAYWRIGHT = 'playwright'
const JEST = 'jest'
const WDS = 'webpack-dev-server'

const isProdBuild = (): boolean => {
  return process.env.NODE_ENV === PROD
}

// HOST_TARGET is injected into the build during a deploy. We do this so we can know for sure
// if we're on dev.voltus.co vs voltapp.voltus.co
// The reason we can't just use NODE_ENV is because we deploy production builds to both dev.voltus.co
// and voltapp.voltus.co, which means NODE_ENV = 'production' for both environments. Instead
// we can know at deploy time what our target is, and inject that as HOST_TARGET
const isProdHost = (): boolean => {
  return process.env.HOST_TARGET === HOST_TARGET.PRODUCTION
}

export { PROD, DEV, TEST, WDS, PLAYWRIGHT, JEST, isProdBuild, isProdHost }
