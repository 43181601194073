import * as React from 'react'
import { StyledProps } from '../../utils/styledSystem'
import { Text } from '../Text'

const BadgeForwardRef = ({ children, bg = 'greens.30', ...props }, ref) => (
  <Text.Helper
    ref={ref}
    borderRadius={4}
    bg={bg}
    px={2}
    py={1}
    color="white"
    textAlign="center"
    whiteSpace="break-spaces"
    display="flex"
    justifyContent="center"
    {...props}
  >
    {children}
  </Text.Helper>
)

const ForwardedBadge = React.forwardRef(BadgeForwardRef)

export type BadgeProps = {
  children?: React.ReactNode
}

const BadgePrimary = (
  { bg = 'greens.5', color = 'greens.70', ...props }: StyledProps & BadgeProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => <ForwardedBadge ref={ref} bg={bg} color={color} {...props} />

const BadgeSecondary = (
  { bg = 'blues.20', color = 'white', ...props }: StyledProps & BadgeProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => <ForwardedBadge ref={ref} bg={bg} color={color} {...props} />

const BadgeTertiary = (
  { bg = 'golds.5', color = 'golds.70', ...props }: StyledProps & BadgeProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => <ForwardedBadge ref={ref} bg={bg} color={color} {...props} />

const BadgeMuted = (
  { bg = 'grays.10', color = 'grays.70', ...props }: StyledProps & BadgeProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => <ForwardedBadge ref={ref} bg={bg} color={color} {...props} />

const BadgeDanger = (
  { bg = 'reds.20', color = 'reds.90', ...props }: StyledProps & BadgeProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => <ForwardedBadge ref={ref} bg={bg} color={color} {...props} />

interface Badge
  extends React.ForwardRefExoticComponent<StyledProps & BadgeProps> {
  (props: StyledProps & BadgeProps): JSX.Element
  Primary: typeof BadgePrimary
  Secondary: typeof BadgeSecondary
  Tertiary: typeof BadgeTertiary
  Muted: typeof BadgeMuted
  Danger: typeof BadgeDanger
}

Object.assign(ForwardedBadge, {
  Primary: React.forwardRef(BadgePrimary),
  Secondary: React.forwardRef(BadgeSecondary),
  Tertiary: React.forwardRef(BadgeTertiary),
  Muted: React.forwardRef(BadgeMuted),
  Danger: React.forwardRef(BadgeDanger),
})

const Badge = ForwardedBadge as Badge

export { Badge }
