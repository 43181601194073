import * as React from 'react'
import { StyledProps } from '../../utils/styledSystem'
import { Box } from '../Box'

type ResponsiveContainerProps = {
  breakpoints?: Array<number | string>
}

/**
 * This container consolidates how we handle switching between
 * the two view modes, by altering the width of the container. On mobile
 * we want everything slightly inset from the boundaries, so we set the
 * width to less than 100%
 */
export const ResponsiveContainer = React.forwardRef<
  HTMLElement,
  StyledProps & ResponsiveContainerProps
>(function ResponsiveContainerForwardRef(
  { children, maxWidth = 1600, ...props },
  ref
) {
  return (
    <Box ref={ref} width="94%" margin="0 auto" maxWidth={maxWidth} {...props}>
      {children}
    </Box>
  )
})
