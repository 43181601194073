import noop from 'lodash/noop'
import { useState, useLayoutEffect } from 'react'
import { Any } from '@voltus/types'

export const useResize = (
  onResize = noop,
  deps: Array<Any> = []
): [number, number] => {
  const [width, setWidth] = useState(document.body.clientWidth)
  const [height, setHeight] = useState(document.body.clientHeight)

  useLayoutEffect(() => {
    const listener = () => {
      setWidth(document.body.clientWidth)
      setHeight(document.body.clientHeight)

      onResize(document.body.clientWidth, document.body.clientHeight)
    }

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
    // eslint hooks warning can be safely ignored here, as we do really
    // want to spread in the passed in dependencies
    // eslint-disable-next-line
  }, [onResize, ...deps])

  return [width, height]
}
