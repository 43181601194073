import styled from '@emotion/styled'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { LocationDescriptor } from 'history'
import * as React from 'react'

import {
  StyledProps,
  propTransformer,
  variant,
  css,
} from '../../utils/styledSystem'

const variants = {
  headline: {
    fontSize: [5, 7],
    color: 'voltus-text-color',
    large: {
      fontSize: [6, 7],
      color: 'voltus-text-color',
    },
    medium: {
      fontSize: [5, 6],
      color: 'voltus-text-color',
    },
    small: {
      fontSize: [4, 5],
      color: 'voltus-text-color',
    },
  },
  title: {
    fontSize: 3,
    color: 'voltus-text-color',
    large: {
      fontSize: 4,
      color: 'voltus-text-color',
    },
    small: {
      fontSize: 3,
      color: 'voltus-text-color',
    },
  },
  label: {
    fontSize: 0,
    color: 'voltus-text-color',
    large: {
      fontSize: 2,
      color: 'voltus-text-color',
    },
    medium: {
      fontSize: 1,
      color: 'voltus-text-color',
    },
    small: {
      fontSize: 0,
      color: 'voltus-text-color',
    },
  },
  paragraph: {
    fontSize: 3,
    color: 'voltus-text-color',
    small: {
      fontSize: 2,
      color: 'voltus-text-color',
    },
  },
  helper: {
    small: {
      fontSize: 1,
      color: 'voltus-text-color',
    },
    fontSize: 2,
    color: 'voltus-text-color',
  },
}

const BaseText = styled('span', {
  shouldForwardProp,
})(
  variant({
    prop: 'variant',
    variants,
  }),
  propTransformer,
  css
)

const getVariantPath = (textStyle, textSize) => {
  if (variants[textStyle]?.[textSize]) {
    return `${textStyle}.${textSize}`
  }

  return textStyle
}

const getAvailableComponents = (variant) => {
  if (!variant) {
    return 'span'
  }

  if (variant.startsWith('headline')) {
    return {
      large: 'h1',
      medium: 'h2',
      small: 'h3',
    }
  }

  if (variant.startsWith('title')) {
    return 'p'
  }
  if (variant.startsWith('label')) {
    return 'span'
  }
  if (variant.startsWith('paragraph')) {
    return 'p'
  }
  if (variant.startsWith('helper')) {
    return 'span'
  }

  return 'span'
}

const getAssumedComponent = (variant, textStyle, textSize) => {
  const availableComponents = getAvailableComponents(variant)
  if (typeof availableComponents === 'object') {
    return availableComponents[textSize] || availableComponents.large
  }

  return availableComponents
}

const TextForwardRef = ({ textStyle, textSize, ...props }, ref) => {
  const variant = props.variant || getVariantPath(textStyle, textSize)
  return (
    <BaseText
      ref={ref}
      as={getAssumedComponent(variant, textStyle, textSize)}
      variant={variant}
      p={0}
      m={0}
      {...props}
    />
  )
}

const ForwardedText = React.forwardRef(TextForwardRef)

enum TextVariant {
  Headline = 'headline',
  Title = 'title',
  Paragraph = 'paragraph',
  // Got SO tired of typing Paragraph
  P = 'paragraph',
  Helper = 'helper',
  Label = 'label',
}

type TextSize = 'large' | 'medium' | 'small'

type TextProps = {
  textSize?: TextSize
  variant?: TextVariant
}
type TextLinkProps =
  | {
      // Props are different if consumer uses <Text.Link as={Link}> vs  <Text.Link as="a" />
      to: LocationDescriptor
      href?: never
      target?: string
      rel?: string
    }
  | { href?: string; to?: never; target?: string; rel?: string }

const TextHeadline = (
  props: StyledProps & TextProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => (
  <ForwardedText
    ref={ref}
    variant={getVariantPath('headline', props.textSize)}
    {...props}
  />
)
const TextTitle = (
  props: StyledProps & TextProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => (
  <ForwardedText
    ref={ref}
    variant={getVariantPath('title', props.textSize)}
    {...props}
  />
)
const TextLabel = (
  props: StyledProps & TextProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => (
  <ForwardedText
    ref={ref}
    variant={getVariantPath('label', props.textSize)}
    {...props}
  />
)
const TextParagraph = (
  props: StyledProps & TextProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => (
  <ForwardedText
    ref={ref}
    variant={getVariantPath('paragraph', props.textSize)}
    {...props}
  />
)

const TextHelper = (
  props: StyledProps & TextProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => (
  <ForwardedText
    ref={ref}
    variant={getVariantPath('helper', props.textSize)}
    {...props}
  />
)

const TextLink = (
  props: StyledProps & TextProps & TextLinkProps,
  ref: React.Ref<HTMLElement>
): JSX.Element => (
  <ForwardedText
    ref={ref}
    variant="helper"
    as="a"
    color="voltus-link-color"
    {...props}
    css={{
      '&:hover': {
        color: 'voltus-link-color--hover',
      },
      textDecoration: 'none',
      ...props.css,
    }}
  />
)

interface Text
  extends React.ForwardRefExoticComponent<StyledProps & TextProps> {
  (props: StyledProps & TextProps): JSX.Element
  Headline: typeof TextHeadline
  Title: typeof TextTitle
  Paragraph: typeof TextParagraph
  P: typeof TextParagraph
  Helper: typeof TextHelper
  Label: typeof TextLabel
  Link: typeof TextLink
}

Object.assign(ForwardedText, {
  Headline: React.forwardRef(TextHeadline),
  Title: React.forwardRef(TextTitle),
  Label: React.forwardRef(TextLabel),
  Paragraph: React.forwardRef(TextParagraph),
  P: React.forwardRef(TextParagraph),
  Helper: React.forwardRef(TextHelper),
  Link: React.forwardRef(TextLink),
})

const Text = ForwardedText as Text

export { Text }
