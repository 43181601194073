import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'
import jwtDecode from 'jwt-decode'
import * as React from 'react'
import {
  ENVIRONMENTS,
  isDemoEnabled,
  isProdHost,
  isTestEnv,
} from '@voltus/utils'
import { DATADOG_TOKENS } from './tokens'

// A custom hook to set the user for datadogRum
// This is so logs and errors are associated with a user
export const useDDUser = (profile) => {
  const [initializedDataDog, setInitializedDataDog] = React.useState(false)
  React.useEffect(() => {
    if (profile && !initializedDataDog) {
      setInitializedDataDog(true)
      datadogRum.setUser({
        email: profile.email,
      })
      if (isProdHost()) {
        datadogRum.startSessionReplayRecording()
      }
    }
  }, [profile, initializedDataDog, setInitializedDataDog])
}

let ENV = 'local'
if (process.env.NODE_ENV === ENVIRONMENTS.PROD) {
  ENV = isProdHost() ? ENVIRONMENTS.PROD : ENVIRONMENTS.DEV
}

// We don't want to report this error to datadog. It's benign and can be safely ignored.
export const ignoredErrorMessages = ['ResizeObserver loop limit exceeded']

type InitDDRumArgs = {
  applicationId: string
  clientToken: string
  service: string
  beforeSend?: RumInitConfiguration['beforeSend']
}
// Wraps the call to datadogRum.init to apply defaults
export const initDDRum = ({
  applicationId,
  clientToken,
  service,
  beforeSend,
  ...rest
}: InitDDRumArgs) => {
  datadogRum.init({
    applicationId,
    clientToken,
    service,
    site: DATADOG_TOKENS.SITE,
    env: ENV,
    version: process.env.RELEASE_NAME,
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [window.location.origin, /https:\/\/.*\.voltus\.co/],
    beforeSend: (evt, ctx) => {
      if (evt.type === 'error' && evt.error) {
        if (ignoredErrorMessages.includes(evt?.error?.message)) {
          // Returning `false` prevents the event from being sent to datadog
          return false
        }
      }

      if (beforeSend) {
        return beforeSend(evt, ctx)
      }
    },
    enableExperimentalFeatures: ['feature_flags'],
    ...rest,
  })
}

// Wraps the call to datadogLogs.init to apply defaults
export const initDDLogs = ({ clientToken, service, ...rest }) => {
  datadogLogs.init({
    clientToken,
    service,
    site: DATADOG_TOKENS.SITE,
    env: ENV,
    // If we want datadog to automatically capture console.errors as logs,
    // we can turn this on. For now, datadog logging is entirely opt-in
    // by using our logger - `@voltus/logger`
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    version: process.env.RELEASE_NAME,
    ...rest,
  })
}

export const initDD = (service: keyof (typeof DATADOG_TOKENS)['SERVICE']) => {
  if (process.env.NODE_ENV === ENVIRONMENTS.PROD && !isTestEnv()) {
    initDDRum({
      applicationId: DATADOG_TOKENS.SERVICE[service].APPLICATION_ID,
      clientToken: DATADOG_TOKENS.SERVICE[service].CLIENT_TOKEN,
      service: service,
    })
    initDDLogs({
      clientToken: DATADOG_TOKENS.SERVICE[service].CLIENT_TOKEN,
      service: service,
    })
    window.addEventListener('error', (evt) => {
      if (ignoredErrorMessages.includes(evt?.message)) {
        return
      }

      const e = evt.error ?? evt.message
      const jwt = Cookies.get('access_token')
      const userEmail = jwt
        ? jwtDecode<{ identity?: { email: string } }>(jwt)?.identity?.email
        : ''
      datadogRum.addError(e, {
        userEmail,
        isDemoMode: isDemoEnabled(),
        unhandled: true,
        isGlobal: true,
      })
    })
  }
}

export const ddErrorHandler = (error) => {
  const jwt = Cookies.get('access_token')
  const userEmail = jwt
    ? jwtDecode<{ identity?: { email: string } }>(jwt)?.identity?.email
    : ''
  datadogRum.addError(error, {
    isDemoMode: isDemoEnabled(),
    userEmail,
    react: true,
    unhandled: true,
    isGlobal: true,
  })
}

const ddLogger = datadogLogs.logger
// Re-export for convenience
export { datadogRum, datadogLogs, ddLogger }
