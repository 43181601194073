import * as React from 'react'
import { Card } from '../Card'
import { Flex } from '../Flex'
import { ResponsiveContainer } from '../ResponsiveContainer'
import { Text } from '../Text'

export const ErrorFallback = () => {
  return (
    // TODO: This component should not define its own marginTop.
    // Leaving this here for now for backwards compatibility until we can update
    // relevant pages to not depend on it.
    <ResponsiveContainer mt={3}>
      <Card as={Flex.Column} p={3} gap={3}>
        <Text.Headline textSize="small">
          VoltApp has encountered an error!
        </Text.Headline>
        <Text.P>
          <Text.Link fontSize={3} as="a" href={location.href}>
            Click here
          </Text.Link>{' '}
          to try again.
        </Text.P>
        <Text.P>
          If you continue to see this error, please let us know by emailing{' '}
          <Text.Link fontSize={3} as="a" href="mailto:support@voltus.co">
            support@voltus.co
          </Text.Link>
        </Text.P>
      </Card>
    </ResponsiveContainer>
  )
}
