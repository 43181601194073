import classnames from 'classnames'
import { noop } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { isDemoEnabled, useResize } from '@voltus/utils'
import { Icons } from '../../icons'
import { Badge } from '../Badge'
import { Flex } from '../Flex'
import stylesheet from './Navbar.scss'
import { DropdownNavItem } from './components/DropdownNavItem'
import { IconNavItem } from './components/IconNavItem'
import NavItem from './components/NavItem'
import ProfileNavItem from './components/ProfileNavItem'
import { Provider, useNavbarContext } from './navbar-context'

interface NavbarProps {
  children?: React.ReactNode
  onChange?: (route: string) => void
}

/**
 * Flexible Navbar component. Requires passing the children
 * that you want to be visible. This allows for customizing each
 * individual link as needed, for instance, if some need to be hard links
 * or others need to be react-router links.
 *
 * This component just accepts children. You are
 * responsible for showing/not showing appropriate links based on the users
 * permissions.
 * E.g.
 *
 * ```tsx
 * <Navbar>
 *  {props.permissions.view_admin ? <Navbar.Item to="/admin">Admin</Navbar.Item>}
 *  <Navbar.Item active to="/realtime-energy">Real Time Energy Data</Navbar.Item>
 *  {...etc}
 * </Navbar>
 * ```
 */
const ForwardedNavbar = React.forwardRef<HTMLElement, NavbarProps>(
  ({ children, onChange = noop }: NavbarProps, ref) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
    const location = useLocation()
    useEffect(() => {
      setMobileMenuOpen(false)
    }, [location])

    // When we resize back above the breakpoint,
    // close the menu to avoid the open menu
    // styles from sticking around, and causing
    // layout issues
    useResize(
      (width) => {
        if (width >= 900 && isMobileMenuOpen) {
          setMobileMenuOpen(false)
        }
      },
      [isMobileMenuOpen, setMobileMenuOpen]
    )

    const handleChange = (route: string) => {
      onChange(route)
    }

    const closeMobileMenu = () => setMobileMenuOpen(false)

    const context = { handleChange, closeMobileMenu }

    return (
      <Provider value={context}>
        <nav
          ref={ref}
          className={classnames(stylesheet.navbarWrapper, {
            [stylesheet.navbarWrapperOpen]: isMobileMenuOpen,
          })}
        >
          <div className={stylesheet.navbar}>
            <div className={stylesheet.navbarLeft}>
              <Flex.Row alignItems="center" gap={3}>
                <Icons.Logo />
                {isDemoEnabled() ? (
                  <Badge.Secondary maxWidth="max-content">
                    Demo mode
                  </Badge.Secondary>
                ) : null}
              </Flex.Row>
              <i
                className={classnames(stylesheet.navbarHamburger, 'fal', {
                  'fa-bars': !isMobileMenuOpen,
                  'fa-times': isMobileMenuOpen,
                  [stylesheet.navbarHamburgerOpen]: isMobileMenuOpen,
                })}
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              />
            </div>
            <ul
              className={classnames(stylesheet.navbarMenu, {
                [stylesheet.navbarMenuOpen]: isMobileMenuOpen,
              })}
            >
              {children}
            </ul>
          </div>
        </nav>
      </Provider>
    )
  }
)

type TForwardedNavbar = typeof ForwardedNavbar
interface Navbar extends TForwardedNavbar {
  (props: NavbarProps): JSX.Element
  Item: typeof NavItem
  Profile: typeof ProfileNavItem
  DropdownNavItem: typeof DropdownNavItem
  Icon: typeof IconNavItem
}

Object.assign(ForwardedNavbar, {
  Item: NavItem,
  Profile: ProfileNavItem,
  DropdownNavItem: DropdownNavItem,
  Icon: IconNavItem,
})

const Navbar = ForwardedNavbar as Navbar

export { Navbar, useNavbarContext }
