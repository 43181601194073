import {
  ThemeProvider as EmotionThemeProvider,
  useTheme as emotionUseTheme,
  withTheme,
} from '@emotion/react'
import React from 'react'
import defaultTheme from '../../constants/theme'

interface ThemeProviderProps {
  theme?: typeof defaultTheme
  children: React.ReactNode
}

function ThemeProvider({ theme, children }: ThemeProviderProps) {
  return (
    <EmotionThemeProvider theme={theme || defaultTheme}>
      {children}
    </EmotionThemeProvider>
  )
}

function useTheme(): typeof defaultTheme {
  const theme = emotionUseTheme() as typeof defaultTheme
  return theme && Object.keys(theme).length ? theme : defaultTheme
}

export { ThemeProvider, withTheme, useTheme, defaultTheme as theme }
