import * as React from 'react'

export const stopProp =
  (fn?: (e: React.MouseEvent | Event) => void) =>
  (e: React.MouseEvent | Event): void => {
    e.stopPropagation()
    if (fn) {
      fn(e)
    }
  }

export const preventDefault =
  (fn?: (e: React.MouseEvent | Event) => void) =>
  (e: React.MouseEvent | Event): void => {
    e.preventDefault()
    if (fn) {
      fn(e)
    }
  }
