import noop from 'lodash/noop'
import { createContext, useContext } from 'react'

const context = createContext({
  handleChange: noop,
  closeMobileMenu: noop,
})

const { Provider, Consumer } = context
const useNavbarContext = () => useContext(context)
export { Provider, Consumer, useNavbarContext }
export default context
