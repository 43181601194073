import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  PromptContainer,
  ToastContainer,
  ActivityIndicator,
  Navbar,
  Toast,
  Flex,
} from '@voltus/core-components'
import { useOffline } from '@voltus/utils'

import { routes } from './routes/routes'

const App = () => {
  const offlineToastId = React.useRef<number | string>()

  useOffline(({ online }) => {
    if (online) {
      if (offlineToastId.current) {
        Toast.dismiss(offlineToastId.current)
        offlineToastId.current = undefined
      }
      Toast.push(<Toast.Success>Reconnected!</Toast.Success>)
    } else {
      offlineToastId.current = Toast.push(
        <Toast.Error>
          Looks like you may have lost your internet connection. Please check
          and try again
        </Toast.Error>
      )
    }
  }, [])

  return (
    <React.Fragment>
      <ToastContainer />
      <Flex.Column minHeight="100vh" width="100%">
        <>
          <Navbar />
          <React.Suspense
            fallback={
              <Flex.FullyCentered width="100%" height="100%" flexGrow={1}>
                <ActivityIndicator.Large />
              </Flex.FullyCentered>
            }
          >
            <Routes>
              {routes.routes.map(({ Component, Wrapper, path, ...props }) => {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Wrapper {...props}>
                        <Component />
                      </Wrapper>
                    }
                  />
                )
              })}
            </Routes>
          </React.Suspense>
        </>
      </Flex.Column>
      <PromptContainer />
    </React.Fragment>
  )
}

export default App
