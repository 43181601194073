import * as React from 'react'
import LoadingSkeleton, {
  SkeletonTheme,
  SkeletonProps,
} from 'react-loading-skeleton'
import { PieChart, Pie } from 'recharts'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTheme } from '../ThemeProvider'

/**
 * Export on top of react-loading-skeleton in order to:
 * 1. Allow for extending it's behavior
 * 2. Centralizing imports across apps
 *
 * React skeleton docs:
 * https://github.com/dvtng/react-loading-skeleton
 */

export const Skeleton = (props: SkeletonProps): JSX.Element => (
  <LoadingSkeleton {...props} />
)

type ThemeProps = {
  color?: string
  highlightColor?: string
  children: React.ReactNode
}
Skeleton.Theme = (props: ThemeProps): JSX.Element => (
  <SkeletonTheme {...props} />
)

interface DonutProps {
  width?: number
  height?: number
}

/**
 *
 * A skeleton stand-in for recharts pie chart
 */
Skeleton.Doughnut = function SkeletonDoughnut({
  width = 138,
  height = 72,
}: DonutProps): JSX.Element {
  const { colors } = useTheme()
  const data = [{ value: 100, fill: colors.grays[5] }]
  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        cx={65}
        cy={65}
        startAngle={180}
        endAngle={0}
        innerRadius={30}
        outerRadius={65}
        dataKey="value"
        isAnimationActive={false}
      />
    </PieChart>
  )
}
