import classnames from 'classnames'
import React from 'react'
import { Box } from '../../Box'
import NavItem, { NavItemProps } from './NavItem'
import stylesheet from './NavItem.scss'

function ProfileNavItem({ active, className, ...props }: NavItemProps) {
  return (
    <NavItem {...props} className={classnames(className, stylesheet.nomargin)}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={33}
        height={33}
      >
        <i
          className={classnames('fas', 'fa-user', stylesheet.profileIcon, {
            [stylesheet.profileIconActive]: active,
          })}
        />
      </Box>
    </NavItem>
  )
}

export default ProfileNavItem
