import styled, { StyledComponent } from '@emotion/styled'
import { StyledProps } from '../../utils/styledSystem'
import { Box } from '../Box'

type StyledComponentWithProps = StyledComponent<StyledProps>

export interface Column extends StyledComponentWithProps {
  FullyCentered: StyledComponentWithProps
}

export interface Row extends StyledComponentWithProps {
  FullyCentered: StyledComponentWithProps
}

export interface IFlex extends StyledComponentWithProps {
  FullyCentered: StyledComponentWithProps
  Column: Column
  Row: Row
}

const Flex = styled(Box)(() => ({
  display: 'flex',
})) as IFlex

const FlexProps = {
  fullyCentered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const FullyCentered = styled(Flex)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}))

Flex.Row = styled(Flex)(() => ({
  flexDirection: 'row',
})) as Row

Flex.Column = styled(Flex)(() => ({
  flexDirection: 'column',
})) as Column

Flex.FullyCentered = FullyCentered

Flex.Row.FullyCentered = styled(FullyCentered)(() => ({
  flexDirection: 'row',
}))

Flex.Column.FullyCentered = styled(FullyCentered)(() => ({
  flexDirection: 'column',
}))

export { Flex, FlexProps }
