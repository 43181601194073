import noop from 'lodash/noop'
import { useEffect } from 'react'
import { Any } from '@voltus/types'

export const useOffline = (
  cb: (param: { online: boolean }, e: Any) => void = noop,
  deps: Array<Any> = []
): void => {
  useEffect(() => {
    const online = (e) => {
      cb(
        {
          online: true,
        },
        e
      )
    }
    const offline = (e) => {
      cb(
        {
          online: false,
        },
        e
      )
    }

    window.addEventListener('offline', offline)
    window.addEventListener('online', online)

    return () => {
      window.removeEventListener('offline', offline)
      window.removeEventListener('online', online)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
