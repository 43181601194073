import * as React from 'react'
import { v4 as uuid } from 'uuid'
import { renderNode } from '@voltus/utils'
import { ButtonProps, Button, BUTTON_KIND } from '../Button'
import { Flex } from '../Flex'
import { Modal } from '../Modal'
import { Text } from '../Text'

interface PromptButtonFn {
  (args: { close: () => void }): JSX.Element
}
interface PromptButtonObjConfig {
  kind?: string
  onClick: (cbs: { close: () => void }) => void
  title?: string
  props?: ButtonProps
}

type PromptButtonConfig = PromptButtonObjConfig | PromptButtonFn

interface PromptConfigBase {
  id: string
  zIndex?: number
  onOpen?: (config: PromptConfig) => void
  onClose?: (config: PromptConfig) => void
}
interface PromptConfigObject extends PromptConfigBase {
  title?: React.ReactNode | (({ close }) => React.ReactNode)
  maxWidth?: number
  message?: React.ReactNode | (({ close }) => React.ReactNode)
  buttons: Array<PromptButtonConfig>
  ButtonContainer?: React.ComponentType<{ children: React.ReactNode }>
}

interface PromptConfigComponent extends PromptConfigBase {
  Component: Parameters<typeof renderNode>[0]
}
type PromptConfig = PromptConfigObject | PromptConfigComponent

let prompts: Array<PromptConfig> = []

const ButtonRowContainer = ({ children }) => (
  <Flex.Row justifyContent="space-between">{children}</Flex.Row>
)
const PromptButton = ({ btn, promptId, index }) => {
  if (!btn) {
    return null
  }

  const close = () => Prompt.remove(promptId)
  if (typeof btn === 'function') {
    return (
      <React.Fragment key={`${promptId}-btn-${index}`}>
        {btn({ close })}
      </React.Fragment>
    )
  }

  return (
    <Button
      {...(btn.props ?? {})}
      kind={btn.kind || BUTTON_KIND.DEFAULT}
      key={`${promptId}-btn-${index}`}
      onClick={() => btn.onClick({ close })}
    >
      {btn.title}
    </Button>
  )
}

interface PromptProps {
  ButtonContainer?: React.ComponentType<{ children: React.ReactNode }>
  title?: React.ReactNode | (({ close }) => React.ReactNode)
  message?: React.ReactNode | (({ close }) => React.ReactNode)
  buttons: Array<PromptButtonConfig>
  id: string
}

interface PromptType {
  (props: PromptProps): JSX.Element
  /**
   * Push a new react component to the prompt stack.
   * e.g.
   * ```tsx
   * // MyComponent will automatically inherit the `close` prop
   * // which closes the prompt modal when called
   * const MyComponent = ({ close }) => {
   *  return <div>Custom prompt</div>
   * }
   * Prompt.push({ Component: <MyComponent /> })
   * ```
   */
  push(prompt: Omit<PromptConfigComponent, 'id'>): string
  /**
   * Push a new prompt config to the prompt stack.
   * e.g.
   * ```tsx
   * Prompt.push({
   *  title: 'My prompt',
   *  message: ()=> <div>Can be a custom component</div>,
   *  buttons: [
   *     { title: 'Cancel', onClick: ({ close }) => close() },
   *     { title: 'Ok', onClick: ({ close }) => {
   *       // ...do something before close
   *       close()
   *     }
   *  ]
   * })
   * ```
   */
  prompt: (config: Omit<PromptConfigObject, 'id'>) => string
  /**
   * Programatically remove a prompt from the stack.
   * e.g.
   * ```tsx
   * const id = Prompt.prompt({ ...config })
   * // somewhere else:
   * Prompt.remove(id)
   * ```
   */
  remove: (id: string) => void
  setRefresh?: (int: number) => void
}

export const Prompt: PromptType = ({
  ButtonContainer = ButtonRowContainer,
  title,
  message,
  buttons,
  id,
  ...rest
}: PromptProps): JSX.Element => {
  const renderTitle = (title) => {
    if (!title) {
      return null
    }

    if (typeof title === 'function') {
      return title({ close: () => Prompt.remove(id) })
    }

    return <Text.Headline textSize="small">{title}</Text.Headline>
  }

  const renderMessage = (message) => {
    if (!message) {
      return null
    }

    if (typeof message === 'function') {
      return message({ close: () => Prompt.remove(id) })
    }

    return (
      <Text.Paragraph mb={3} mt={3}>
        {message}
      </Text.Paragraph>
    )
  }

  return (
    <Flex.Column minHeight={150} minWidth={300} {...rest}>
      <Flex.Column flex={1}>
        {renderTitle(title)}
        {renderMessage(message)}
      </Flex.Column>
      <ButtonContainer>
        {buttons.map((btn, i) => (
          <PromptButton btn={btn} key={`${id}-${i}`} promptId={id} index={i} />
        ))}
      </ButtonContainer>
    </Flex.Column>
  )
}

const isPromptComponent = (
  prompt: PromptConfig
): prompt is PromptConfigComponent => {
  return 'Component' in prompt
}

export const PromptContainer = () => {
  const [, setRefresh] = React.useState(0)
  Prompt.setRefresh = setRefresh

  return (
    <>
      {prompts.map((prompt) => {
        const renderInner = () => {
          if (isPromptComponent(prompt)) {
            return renderNode(prompt.Component, {
              key: prompt.id,
              close: () => Prompt.remove(prompt.id),
              promptId: prompt.id,
            })
          }

          return (
            <Prompt
              {...prompt}
              id={prompt.id}
              title={prompt.title}
              ButtonContainer={prompt.ButtonContainer}
              message={prompt.message}
              buttons={prompt.buttons}
            />
          )
        }
        return (
          <Modal
            key={prompt.id}
            onRequestClose={() => Prompt.remove(prompt.id)}
            showCloseButton={false}
            zIndex={prompt.zIndex ?? 10000}
            // This is a HACK. There's a bug where because the prompt doesn't
            // use the `isOpen` prop the way the modal expects, when a prompt
            // is closed, the modal doesn't correct remove the bodyOpenClassName
            // and because we set `overflow: hidden` on the body when a modal
            // is open to prevent scrolling, that breaks the page. making non-scrollable
            // until a refresh happens. Setting a null prop prevents
            // the modal from setting the bodyOpenClassName and thus skirts the issue
            // A real solution is probably to use context to manage the Prompts, rather
            // than the global module level array
            bodyOpenClassName={null}
            isOpen
          >
            {renderInner()}
          </Modal>
        )
      })}
    </>
  )
}

Prompt.prompt = (prompt: Omit<PromptConfigObject, 'id'>) => {
  const id = uuid()
  prompt.onOpen?.({
    id,
    ...prompt,
  })
  prompts.push({
    id,
    ...prompt,
  })
  Prompt.setRefresh?.(Math.random())
  return id
}

Prompt.push = (prompt: Omit<PromptConfigComponent, 'id'>) => {
  const id = uuid()
  prompts.push({
    id,
    ...prompt,
  })
  prompt.onOpen?.({
    id,
    ...prompt,
  })
  Prompt.setRefresh?.(Math.random())
  return id
}

Prompt.remove = (id) => {
  prompts = prompts.filter((prompt) => {
    if (prompt.id === id) {
      prompt.onClose?.(prompt)
      return false
    }

    return true
  })
  Prompt.setRefresh?.(Math.random())
}
